<template lang="pug">
.Content
  Header
  .headerFixed(v-if="isntIntersection && !isCorrect")
    button(v-if="!isCorrect" @click="goToView({view: 'Autenticate'})" type="button").button-without-styles.py-3.px-4
      svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    button(v-else @click="isCorrect = !isCorrect" type="button").button-without-styles.py-3.px-4
      svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    h2(v-if="isCorrect").TitleSection.text-start.color-white.pt-2 ¿De dónde es tu orden médica?
  .Page(v-if="isPopup")
    .contain.VerificationDataPage(style="padding-bottom: 275px !important;")
      .d-flex.justify-content-between.align-items-center
        img(@click="onGoPopup" style="width: 28px;" src="@/assets/kiosco46/arrow.svg")
        Timer(:containerImageDocs="containerImageDocs" :timer="timer" :showProgressBar="showProgressBar")
      h2.Title.text-center.pt-1.pb-3#headerCalendarTitle2 ¡Información!
      p(style="font-size: 32px").Subtitle.mt-3.text-center.specialText.mb-0 En este canal solo se autorizan servicios médicos cubiertos por tu plan de medicina prepagada, si tu solicitud es de medicamentos ambulatorios te invitamos a conocer el nuevo proceso a través de tu EPS
      .botton-actions-footer(style="bottom: -180px")
        .d-flex.justify-content-between.mb-3
          b-button(variant="bluecolmedica" size="lg" type="button" @click="handleUnderstand" ).text-center.buttonDK.mt-0
            span(style="font-size: 34px").buttonText.m-0.p-0 Entendido
  .Page(v-else-if="!isCorrect" style="bottom: 300px")
    .contain.VerificationDataPage
      .d-flex.justify-content-between.align-items-center
        img(@click="goToView({view: 'Autenticate'})" style="width: 28px;" src="@/assets/kiosco46/arrow.svg")
        Timer(:containerImageDocs="containerImageDocs" :timer="timer" :showProgressBar="showProgressBar")
      .text-center.d-block
        img(src="@/assets/kiosco46/infoIcon.svg" alt="infoIcon").infoIcon
      p.TextSection.mt-2.mb-5.mx-3.color-dark.Subtitle(style="line-height: 35px;") Confirma la información del usuario que requiere la autorización
      .form-data.mb-3.mt-4.p-4
        .d-flex.align-items-start.boxOfText
          p.buttonText.mb-0 Documento:
          p.mb-0.ml-1.buttonText: strong {{ selectedOption }}
        .d-flex.align-items-start.boxOfText.flex-wrap
          p.buttonText.mb-0.text-break-white.mr-1 Número de documento:
          p.mb-0.buttonText: strong {{ docNumber }}
        .d-flex.align-items-start.boxOfText
          p.buttonText.mb-0 Nombre:
          p.mb-0.ml-1.buttonText: strong {{ name }}
        .d-flex.align-items-start.boxOfText
          p.buttonText.mb-0.text-break-white Celular:
          p.mb-0.ml-1.buttonText: strong {{ phone }}
        .d-flex.align-items-start.boxOfText(style="flex-wrap: wrap;")
          p.buttonText.mb-0.text-break-white Correo electrónico:
          p.mb-0.d-flex.flex-wrap.ml-1.buttonText(style="justify-content: center; width: 100%;")
            strong {{ email }}
        .pt-5.d-block
          strong.buttonText Enviaremos la autorización al correo señalado.
          span.ml-1.buttonText Si requieres actualizarlo, no olvides informarselo a tu agente Colmédica antes de finalizar la videollamada.
      .py-4
        b-button(variant="bluecolmedica" size="lg" type="button" @click="handleContinue").text-center.buttonDK.mt-0
          span.buttonText Continuar con estos datos

  .Page(v-else)
    .contain.containNew.VerificationDataPage
      .d-flex.justify-content-between.align-items-center
        img(@click="isPopup = true" style="width: 28px;" src="@/assets/kiosco46/arrow.svg")
        Timer(:containerImageDocs="containerImageDocs" :timer="timer" :showProgressBar="showProgressBar")
      h2.Title.text-start.pt-1.ml-2#headerCalendarTitle2 ¿De dónde es tu orden médica?
      p.Subtitle.mt-3.text-start.specialText.mb-0.mr-3 La orden médica a autorizar te la entregó un médico de:
      .py-4.containerStacks
        b-button(variant="bluecolmedica" size="lg" type="button" @click="loadData('Centro Médico')" style="width: 500px; max-width: 100%;").text-center.buttonDK.my-4.d-flex.justify-content-between.align-items-center
          span.buttonText Centro Médico Colmédica
          svg(width='11' height='18' viewbox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M2 2L8.99954 8.99954' stroke='#FFF' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
            path(d='M2 15.9688L8.99954 8.96921' stroke='#FFF' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
        b-button(variant="bluecolmedica" size="lg" type="button" @click="loadData('Profesional de la red', 'files')" style="width: 500px; max-width: 100%;").my-4.text-center.buttonDK.d-flex.justify-content-between.align-items-center
          span.buttonText Profesional de la red
          svg(width='11' height='18' viewbox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M2 2L8.99954 8.99954' stroke='#FFF' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
            path(d='M2 15.9688L8.99954 8.96921' stroke='#FFF' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
        b-button(variant="bluecolmedica" size="lg" type="button" @click="loadData('Clínica', 'files')" style="width: 500px; max-width: 100%;").text-center.buttonDK.my-4.d-flex.justify-content-between.align-items-center
          span.buttonText Clínica
          svg(width='11' height='18' viewbox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M2 2L8.99954 8.99954' stroke='#FFF' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
            path(d='M2 15.9688L8.99954 8.96921' stroke='#FFF' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold";
import { utf8_to_b64 } from "@/utils/decoder";
import asterisker from "@/helpers/asteriskHidden";
import { getOneUseToken } from "@/helpers/Meet";
import _get from "lodash/get";
import StackItem from "@/views/components/StackItem.vue";
import Timer from "./components/Timer.vue";

export default {
  name: "VerificationData",
  components: {
    Header,
    Back,
    Continue,
    StackItem,
    Timer
  },
  data: () => ({
    isCorrect: false,
    isntIntersection: false,
    isPopup: false
  }),
  mounted() {
    // tracking
    setTimeout(() => {
      let auth = !this.client
        ? " autentitacion-no-cliente"
        : "autenticacion-cliente";
      let trackingData = {
        uuid: window.person.id,
        accion: "customer-" + auth,
        debmedia_turn_code: "",
        url_origen: window.location.href,
        origen: _get(this.client, "fu") || "ad",
        mensajes: ""
      };

      this.sendTracking(trackingData);
    }, 1000);
  },
  computed: {
    ...mapState({
      options: state => state.autoservice.tipide,
      client: state => state.virtualrow_atril_video.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      HorarioOutContinue: state => state.autoservice.HorarioOutContinue,
      currentUser: state => state.virtualrow.currentUser,
      explanation: state => state.autoservice.explanation,
      stopTimer: state => state.autoservice.stopTimer,
      containerImageDocs: state =>
        state.virtualrow_atril_video.containerImageDocs,
      showProgressBar: state => state.virtualrow_atril_video.showProgressBar,
      timer: state => state.virtualrow_atril_video.timer
    }),

    selectedOption() {
      return this.options.length
        ? this.options.find(option => option.value === this.b64final.ti)?.text
        : "";
    },

    docNumber() {
      return asterisker(this.b64final.nu, 0, 4);
    },

    name() {
      console.log(this.client);
      let pureName = (_get(this.client, "Nombres") || "").split(" ");
      let pureLastName1 = _get(this.client, "PrimerApellido") || "";
      let pureLastName2 = _get(this.client, "SegundoApellido") || "";
      return [...pureName, pureLastName1, pureLastName2]
        .filter(data => !!data)
        .map(word => word) // Before: asterisker(word, 2, 2)
        .join(" ");
    },

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      let secondPartEmail = "";

      if (splited.length > 0)
        secondPartEmail = `@${asterisker(
          splited[1],
          0,
          splited[1]?.length - 2
        )}`;

      return `${asterisker(splited[0], 2, 2)}${secondPartEmail}`;
    }
  },
  methods: {
    ...mapActions({
      goToView: "virtualrow_atril_video/goToView",
      setService: "autoservice/setService",
      updateB64Final: "autoservice/updateB64Final",
      sendTracking: "virtualrow_atril_video/handleTracking"
    }),

    onGoPopup() {
      this.isPopup = false;
      this.isCorrect = false;
    },

    handleContinue() {
      this.isntIntersection = false;
      const timer = setTimeout(() => {
        if(this.handlerObserver){
          this.observer = new IntersectionObserver(this.handlerObserver, {
            threshold: 1.0
          });
  
          this.observer.observe(document.querySelector("#headerCalendarTitle2"));
          clearTimeout(timer);
        }
      }, 10);

      // Check ControlMedicamentos for popup
      if(this.client?.ControlMedicamentos) this.isPopup = true;
      
      this.isCorrect = !this.isCorrect;
    },

    handleUnderstand() {
      this.isPopup = false;
      this.isCorrect = true;
    },

    async toTurn() {
      let obj = {
        na: this.name
      };
      if (this.currentUser) obj.ma = this.currentUser.PrioridadMarcaValor;
      this.updateB64Final(obj);
      if (this.HorarioOutContinue) this.goToView({ view: "UploadFiles" });
      else {
        let to = await getOneUseToken();
        let jsonB64 = JSON.stringify({
          ...this.b64final,
          sv: this.service,
          to
        });
        this.$router.push({
          name: "DecoderKiosco46",
          query: {
            pr: utf8_to_b64(jsonB64),
            branchid: this.$route.query.branchid
          }
        });
      }
    },
    loadData(type, action) {
      console.log(type, action);
      this.setService(type);
      if (!action) {
        this.toTurn();
        // return this.goToView("IndexSchedules");
      } else this.goToView({ view: "UploadFiles" });
    }
  }
};
</script>
<style scoped>
.contain {
  /* position: relative; */
  width: 100%;
  /* height: calc(100% - (58px + 22px + 50px)); */
}
.containNew {
  margin-top: 0;
}

/* Zone Actions Verify */
.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.actions-zone {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;
}

.min-button {
  min-width: 100px;
}

/* Zone Action Request */
.actions-zone-2 {
  margin-top: 30px;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  bottom: 50px;
  /* width: 100%; */
  left: 48%;
  transform: translateX(-50%);
  z-index: 800;
}

.botton-actions-2 {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: 0;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.VerificationDataPage {
  background-color: #f2f4f8;
  padding: 50px;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.buttonDK {
  max-width: 450px;
  margin: auto;
  display: block;
  margin-top: 29px;
  line-height: 65px;
  padding: 9px 3.5rem;
  font-weight: 600;
}

.button-origen {
  font-size: 1.3rem;
}

.specialText {
  color: var(--color-dark);
}

.inputStyles {
  border: 1px solid #b8b8b8;
  border-radius: 15px;
  background-color: var(--color-white);
  color: var(--color-disabled-text);
}

.font-16 {
  font-size: 35px;
  line-height: 40px;
}

/* .Subtitle {
  line-height: 75px;
} */

.inputStyles::placeholder {
  color: var(--color-disabled-text);
  font-weight: 500;
}

.Subtitle2 {
  font-size: 45px;
}

.text-break-white {
  white-space: nowrap !important;
}

.containerStacks {
  max-width: 477px;
  margin: auto;
}

.btn-bluecolmedica {
  width: 100%;
}

.botton-actions-footer {
  padding-bottom: 172px;
  transform: translateX(-50%);
  max-width: 377.7px;
  width: 100%;
  z-index: 200;
}

.infoIcon {
  max-width: 50px;
}

.StackItem {
  padding: 3.2rem 0;
}

.StackItem-span {
  font-size: 50px;
}
</style>
