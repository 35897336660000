<template lang="pug">
.Error
  CloseThick(size="150px")
</template>

<script>
import CloseThick from "mdi-vue/CloseThick.vue";

export default {
  name: "Error",
  components: {
    CloseThick
  }
};
</script>

<style scoped>
.Error {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
</style>

<style scoped>
.Error {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
</style>